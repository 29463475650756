<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-primary">
          <div class="card-icon">
            <md-icon>people</md-icon>
          </div>
          <h4 class="title">Administrators</h4>
        </md-card-header>
        <md-card-content>
          <md-table :value="queriedData" :md-sort.sync="currentSort" :md-sort-order.sync="currentSortOrder" :md-sort-fn="customSort" class="paginated-table table-striped table-hover">
            <md-table-toolbar>
              <md-field>
                <label for="pages">Per page</label>
                <md-select v-model="pagination.perPage" name="pages">
                  <md-option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item">
                    {{ item }}
                  </md-option>
                </md-select>
              </md-field>
              <md-field>
                <md-input
                  type="search"
                  class="mb-3"
                  clearable
                  style="width: 200px"
                  placeholder="Search administrators"
                  v-model="searchQuery">
                </md-input>
              </md-field>
            </md-table-toolbar>
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="#" class="id-cell">{{ item.id }}</md-table-cell>
              <md-table-cell md-label="" >
                <div class="img-container">
                  <img :src="item.avatar" :alt="item.name"/>
                </div>
              </md-table-cell>
              <md-table-cell md-label="Name" md-sort-by="name">{{ item.name }}</md-table-cell>
              <md-table-cell md-label="Email" md-sort-by="email">{{ item.email }}</md-table-cell>
              <md-table-cell md-label="Active">
                <md-icon v-if="item.active==='1'" class="active">check_circle_outline</md-icon>
                <md-icon v-else class="inactive">block</md-icon>
              </md-table-cell>
              <md-table-cell md-label="Actions" class="actions-cell">
                <md-button class="md-just-icon md-simple md-info" @click.native="$router.push({name: 'Edit Administrator', params: {id: item.id}})">
                  <md-icon>edit</md-icon>
                </md-button>
                <md-button class="md-just-icon md-simple md-danger" @click.native="showDeleteAdminConfirm(item.id, item.name)">
                  <md-icon>delete_forever</md-icon>
                </md-button>
              </md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class="">
            <p class="card-category">Showing {{from + 1}} to {{to}} of {{total}} entries</p>
          </div>
          <pagination class="pagination-no-border pagination-success"
                        v-model="pagination.currentPage"
                        :per-page="pagination.perPage"
                        :total="total">
          </pagination>
        </md-card-actions>
      </md-card>
    </div>
  </div>
</template>
<script>
import {Pagination} from '@/components'
import Fuse from 'fuse.js'
import swal from 'sweetalert2'

export default {
  components: {
    Pagination
  },
  computed: {
    queriedData () {
      let result = this.tableData;
      if(this.searchedData.length > 0){
        result = this.searchedData;
      }
      return result.slice(this.from, this.to)
    },
    to () {
      let highBound = this.from + this.pagination.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    },
    from () {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    total () {
      return this.searchedData.length > 0 ? this.searchedData.length : this.tableData.length;
    }
  },
  data() {
    return {
      tableData: [],
      currentSort: 'name',
      currentSortOrder: 'asc',
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 25, 50],
        total: 0
      },
      searchQuery: '',
      propsToSearch: ['name', 'email'],
      searchedData: [],
      fuseSearch: null
    }
  },
  created () {
    this.loadAdmins()
  },
  methods: {
    loadAdmins () {
      var self = this
      var xhr = new XMLHttpRequest()
      xhr.open('GET', this.$root.apiUrl + 'v1/admins?fields=id,name,email,avatar,active&sort_by=name.asc&paginate=true')
      xhr.setRequestHeader('Authorization', localStorage.getItem('token'))
      xhr.onload = function () {
        if (xhr.status === 401) self.$router.push({name: 'Login'})
        var response = JSON.parse(xhr.responseText)
        if (xhr.status === 200 && response.response && response.response.items) {
          self.tableData = response.response.items
          self.fuseSearch = new Fuse(self.tableData, {keys: ['name', 'email'], threshold: 0.3})
        } else if (xhr.status === 404) {
          self.tableData = []
        } else {
          var message
          if (response.response && response.response[0] && response.response[0].message)
            message = response.response[0].message
          else
            message = 'Unknown error'
          self.showSweetAlertError(message)
        }
      }
      xhr.send()
    },
    customSort (value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort
        if (this.currentSortOrder === 'desc') {
          return a[sortBy].localeCompare(b[sortBy])
        }
        return b[sortBy].localeCompare(a[sortBy])
      })
    },
    showSweetAlertError (message) {
      swal({
        title: 'An error occured!',
        text: message,
        type: 'warning',
        showConfirmButton: false,
        showCancelButton: true,
        cancelButtonText: 'OK',
        cancelButtonClass: 'md-button md-success',
        buttonsStyling: false
      })
    },
    showDeleteAdminConfirm (id, adminName) {
      swal({
        title: 'Are you sure to delete<br />' + adminName +'?',
        text: '(Experiments created by ' + adminName + ' will not be deleted)',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete!',
        cancelButtonText: 'No, I was kidding',
        confirmButtonClass: 'md-button md-success',
        cancelButtonClass: 'md-button md-danger',
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {
          var self = this
          var xhr = new XMLHttpRequest()
          xhr.open('DELETE', this.$root.apiUrl + 'v1/admins/' + id, true)
          xhr.setRequestHeader('Authorization', localStorage.getItem('token'))
          xhr.onload = function () {
            if (xhr.status === 401) self.$router.push({name: 'Login'})
            var response = JSON.parse(xhr.responseText)
            if (xhr.status === 201) {
              swal({
                title: 'Deleted!',
                text: adminName + ' has been deleted.',
                type: 'success',
                confirmButtonClass: 'md-button md-success',
                buttonsStyling: false
              }).then(() => {
                self.loadAdmins()
              })
            } else {
              var message
              if (response.response && response.response[0] && response.response[0].message)
                message = response.response[0].message
              else
                message = 'Unknown error'
              self.showSweetAlertError(message)
            }
          }
          xhr.send()
        } else if (result.dismiss === swal.DismissReason.cancel) {
          swal({
            title: 'Cancelled',
            text: adminName + ' is safe :)',
            type: 'error',
            confirmButtonClass: 'md-button md-success',
            buttonsStyling: false
          })
        }
      })
    }
  },
  watch: {
    /**
      * Searches through the table data by a given query.
      * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
      * @param value of the query
      */
    searchQuery(value){
      let result = this.tableData;
      if (value !== '') {
        result = this.fuseSearch.search(this.searchQuery)
      }
      this.searchedData = result;
    }
  }
}
</script>
<style lang="scss" scoped>
.text-right /deep/ .md-table-cell-container {
  display: flex;
  justify-content: flex-end;
}
.md-table /deep/ .md-table-head:last-child {
  text-align: right;
}

.md-icon.active {
  color: #4caf50;
}
.md-icon.inactive {
  color: #ef5350;
}

.paginated-table table > tbody > tr > td {
  width: auto;
}
.md-table-toolbar {
  margin-bottom: 30px;
}
td .img-container {
  width: 36px;
  height: 36px;
  overflow: hidden;
  border-radius: 50%;
  // -webkit-box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
  // box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}
.id-cell {
  width: 20px !important;
}
.actions-cell .md-button {
  border: 1px #ddd solid;
  margin: 2px 0px 2px 5px;
  background-color: #fff !important;
  padding: 2px;
}
.actions-cell .md-button:hover {
  background-color: #ddd !important;
}
</style>
